@tailwind base;
@tailwind components;
@tailwind utilities;

.table-content-height {
    max-height: calc(100dvh - 86px - 130px - 96px - 64px - 16px - 76px);
  }

.table-without-table-filter-height {
    max-height: calc(100dvh - 86px - 96px);
  }

.main-content-max-height {
  max-height: calc(100dvh - 64px);
  overflow-y: auto;
}
  
  /* table filter height: 86 px */
  /* table summary height: 130 px */
  /* page header height: 96 px */
  /* top navbar height: 64 px */
  
  /* space for button and margin at bottom: 76px & 16px */

  ::-webkit-scrollbar {
    @apply h-1 w-1 bg-primary-900 dark:bg-primary-900 !important;
  }
  
  ::-webkit-scrollbar-track {
    @apply bg-primary-800 dark:bg-primary-800 !important; /* color of the tracking area */
  }
  
  ::-webkit-scrollbar-thumb {
    @apply bg-primary-500 dark:bg-primary-500 !important; /* color of the scroll thumb */
  }